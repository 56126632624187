<template>
  <section id="price-calculation">
    <b-overlay :show="show" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Price calculation
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-price-calculation" />
          </h4>
          <b-popover target="popover-price-calculation" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="priceCalculationTable.currentPage"
          :items="priceCalculationTable.items" :fields="priceCalculationTable.fields"
          :sort-by.sync="priceCalculationTable.sortBy" :sort-desc.sync="priceCalculationTable.sortDesc"
          :sort-direction="priceCalculationTable.sortDirection" :filter="priceCalculationTable.filter"
          :filter-included-fields="priceCalculationTable.filterOn" @row-clicked="priceCalculationTableRowClicked">
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="priceCalculationTable.currentPage" :total-rows="priceCalculationTable.totalRows"
              first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="price-group-details-modal" size="xl" title="Price group details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true" @hidden="resetPriceGroupDetailsModal">
      <b-overlay :show="showPriceGroupDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              {{ priceGroup }}
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-price-group" />
            </h4>
            <b-popover target="popover-price-group" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative" :current-page="priceGroupBySKUTable.currentPage"
            :items="priceGroupBySKUTable.items" :fields="priceGroupBySKUTable.fields"
            :sort-by.sync="priceGroupBySKUTable.sortBy" :sort-desc.sync="priceGroupBySKUTable.sortDesc"
            :sort-direction="priceGroupBySKUTable.sortDirection" :filter="priceGroupBySKUTable.filter"
            :filter-included-fields="priceGroupBySKUTable.filterOn" />
          <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
            <!-- pagination -->
            <div>
              <b-pagination v-model="priceGroupBySKUTable.currentPage" :total-rows="priceGroupBySKUTable.totalRows"
                first-number last-number prev-class="prev-item" next-class="next-item" class="mb-0">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </b-card-body>
        </b-card>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BPagination,
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BModal,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';

const currentDate = new Date();
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BModal,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
  },
  data() {
    return {
      show: true,
      showPriceGroupDetails: true,
      priceCalculationTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'price_group',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      priceGroupBySKUTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'sku',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [],
        /* eslint-disable global-require */
        items: [],
      },
      queryParams: {},
      priceGroup: '',
    }
  },
  async created() {
    try {
      await this.getPriceCalculation();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    capitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getPriceCalculation() {
      this.show = true;
      try {
        const dateRanges = [
          `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-08-31`,
          `${currentDate.getFullYear()}-09-01 to ${currentDate.getFullYear()}-12-31`,
        ];
        let combinedResults = {};
        for (let i = 0; i < dateRanges.length; i++) {
          const dateRange = dateRanges[i].split(' to ');
          let queryParams = {
            from_date: dateRange[0],
            to_date: dateRange[1]
          };
          const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/price-calculation/`, queryParams);
          let results = response.data.results;

          // Process each result
          results.forEach(result => {
            const groupKey = result.price_group;
            if (!combinedResults[groupKey]) {
              combinedResults[groupKey] = { price_group: groupKey };
            }

            // Append each result with the date range tag to the respective price group
            combinedResults[groupKey][`price_factor ${dateRange}`] = result.price_factor !== undefined ? parseFloat(result.price_factor).toLocaleString('de-DE') : '';
            combinedResults[groupKey][`margin ${dateRange}`] = result.margin !== undefined ? result.margin.toLocaleString('de-DE') : '';
            combinedResults[groupKey][`qty_sold ${dateRange}`] = result.qty_sold;
          });
        }

        let finalTable = Object.values(combinedResults);
        const fieldSet = new Set(['price_group']);
        finalTable.forEach(item => {
          Object.keys(item).forEach(key => {
            fieldSet.add(key);
          });
        });
        const fields = Array.from(fieldSet).map(field => ({
          key: field,
          label: this.capitalize(field.replace(/_/g, ' ').replace(',', ' to ')),
          sortable: true,
        }));

        this.priceCalculationTable.fields = fields;
        this.priceCalculationTable.items = finalTable;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show = false;
      }
    },
    async getPriceGroupBySKU() {
      this.showPriceGroupDetails = true;
      try {
        const priceRange = this.priceGroup.split(' - ');
        let queryParams = {};
        queryParams.from_price = priceRange[0];
        queryParams.to_price = priceRange[1];

        const dateRanges = [
          `${currentDate.getFullYear() - 1}-01-01 to ${currentDate.getFullYear() - 1}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
          `${currentDate.getFullYear()}-01-01 to ${currentDate.getFullYear()}-08-31`,
          `${currentDate.getFullYear()}-09-01 to ${currentDate.getFullYear()}-12-31`,
        ];
        let combinedResults = {};
        for (let i = 0; i < dateRanges.length; i++) {
          const dateRange = dateRanges[i].split(' to ');
          queryParams.from_date = dateRange[0];
          queryParams.to_date = dateRange[1];
          const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/price-group-by-sku/`, queryParams);
          let results = response.data.results;

          // Process each result
          results.forEach(result => {
            const groupKey = result.sku;
            if (result.sku !== undefined) {
              if (!combinedResults[groupKey]) {
                combinedResults[groupKey] = { sku: groupKey };
              }

              // Append each result with the date range tag to the respective price group
              combinedResults[groupKey]['name'] = result.name;
              combinedResults[groupKey][`margin ${dateRange}`] = result.margin !== undefined ? result.margin.toLocaleString('de-DE') : '';
              combinedResults[groupKey][`qty_sold ${dateRange}`] = result.qty_sold;
            }
          });
        }

        let finalTable = Object.values(combinedResults);
        const fieldSet = new Set(['sku']);
        finalTable.forEach(item => {
          Object.keys(item).forEach(key => {
            fieldSet.add(key);
          });
        });
        const fields = Array.from(fieldSet).map(field => ({
          key: field,
          label: this.capitalize(field.replace(/_/g, ' ').replace(',', ' to ')),
          sortable: true,
        }));

        this.priceGroupBySKUTable.fields = fields;
        this.priceGroupBySKUTable.items = finalTable;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPriceGroupDetails = false;
      }
    },
    priceCalculationTableRowClicked(row) {
      this.priceGroup = row.price_group;
      this.getPriceGroupBySKU();
      this.$refs['price-group-details-modal'].show();
    },
    resetPriceGroupDetailsModal() {
      this.priceGroup = '';
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>